@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --tg-color-scheme: dark;
    --tg-theme-bg-color: #212121;
    --tg-theme-button-color: #8774e1;
    --tg-theme-button-text-color: #ffffff;
    --tg-theme-hint-color: #aaaaaa;
    --tg-theme-link-color: #8774e1;
    --tg-theme-secondary-bg-color: #181818;
    --tg-theme-text-color: #ffffff;
    --tg-viewport-height: 100vh;
    --tg-viewport-stable-height: 100vh;
  }
}

#root {
  @apply flex min-h-screen w-screen items-center justify-center bg-background text-text;
}

body::-webkit-scrollbar {
  display: none; /* Скрытие полосы прокрутки в WebKit-браузерах (Chrome, Safari) */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn-group > .btn:not(.btn-active):hover {
  background-color: rgb(var(--primary) / var(--tw-bg-opacity));
}
